import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const PrivacyPolicy = () => {
    return (
        <div class="about_sectiom">
            <div class="abt">
                <div className="product_slider9" data-aos="fade-up">
                    <Container>
                        <Row>
                            <Col>
                                <h3>Privacy <span className=""> Policy</span></h3>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Container>
                <div class="static-page-box">
                    <h3>Welcome to Cityplots!</h3>
                    <p>Cityplots - Terms &amp; Conditions Welcome to Cityplots! These terms and conditions outline
                        the rules and regulations for the use of Cityplots' Website.</p>
                    <p>By accessing this website, you agree to accept these terms and conditions. Do not continue to
                        use Cityplots if you do not agree to all the terms and conditions stated on this page.
                    </p>

                    <h3>Definitions</h3>
                    <p>The following terminology applies to these Terms and Conditions, Privacy Statement and
                        Disclaimer Notice, and all Agreements: "Client", "You" and "Your" refer to you, the person
                        accessing this website and compliant to the company’s terms and conditions. "The Company",
                        "Ourselves", "We", "Our", and "Us", refer to Cityplots. "Party", "Parties", or "Us",
                        refers to both the Client and ourselves. All terms refer to the offer, acceptance, and
                        consideration of payment necessary to undertake our assistance to the Client in the most
                        appropriate manner for the express purpose of meeting the Client’s needs in respect of
                        provision of the Company’s stated services, in accordance with and subject to prevailing
                        law.</p>

                    <h3>Use of Cookies</h3>
                    <p>We employ the use of cookies. By accessing Cityplots, you agreed to use cookies in
                        agreement with Cityplots' Privacy Policy.</p>

                    <h3>Intellectual Property Rights</h3>
                    <p>Unless otherwise stated, Cityplots and/or its licensors own the intellectual property
                        rights for all material on Cityplots. All intellectual property rights are reserved. You
                        may access this from Cityplots for your own personal use, subject to restrictions set in
                        these terms and conditions.</p>

                    <p>You must not:</p>

                    <ul>
                        <li>Republish material from Cityplots.</li>
                        <li>Sell, rent, or sub-license material from Cityplots.</li>
                        <li>Reproduce, duplicate, or copy material from Cityplots.</li>
                        <li>Redistribute content from Cityplots.</li>
                    </ul>

                    <h3>Comments and User Content</h3>
                    <p>Users may post and exchange opinions and information on the website. Cityplots does not
                        filter, edit, publish or review Comments prior to their presence on the website. Comments do
                        not reflect the views and opinions of Cityplots, its agents, and/or affiliates. Comments
                        reflect the views and opinions of the person who post their views and opinions. Cityplots
                        shall not be liable for the Comments or for any liability, damages, or expenses caused
                        and/or suffered as a result of any use of and/or posting of and/or appearance of the
                        Comments on this website.</p>

                    <h3>Hyperlinking to Our Content</h3>
                    <p>The following organizations may link to our Website without prior written approval:</p>

                    <ul>
                        <li>Government agencies;</li>
                        <li>Search engines;</li>
                        <li>News organizations;</li>
                        <li>Online directory distributors may link to our Website in the same manner as they
                            hyperlink to the Websites of other listed businesses;</li>
                        <li>System wide Accredited Businesses except soliciting non-profit organizations, charity
                            shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
                        </li>
                    </ul>

                    <p>These organizations may link to our home page, to publications or to other Website
                        information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply
                        sponsorship, endorsement or approval of the linking party and its products and/or services;
                        and (c) fits within the context of the linking party’s site.</p>
                    <p> We may consider and approve other link requests from the following types of organizations:
                    </p>

                    <ul>
                        <li>Commonly-known consumer and/or business information sources.</li>
                        <li>dot.com community sites.</li>
                        <li>Associations or other groups representing charities</li>
                        <li>Online directory distributors;</li>
                        <li>Internet portals</li>
                        <li>Accounting, law and consulting firms</li>
                        <li>Educational institutions and trade associations</li>
                    </ul>

                    <p>Internet portals
                        Accounting, law and consulting firms
                        Educational institutions and trade associations
                        We will approve link requests from these organizations if we decide that: (a) the link would
                        not make us look unfavorably to ourselves or to our accredited businesses; (b) the
                        organization does not have any negative records with us; (c) the benefit to us from the
                        visibility of the hyperlink compensates the absence of Cityplots; and (d) the link is in
                        the context of general resource information.</p>

                    <p>These organizations may link to our home page so long as the link: (a) is not in any way
                        deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking
                        party and its products or services; and (c) fits within the context of the linking party’s
                        site.</p>

                    <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking
                        to our website, you must inform us by sending an email to Cityplots. Please include your
                        name, your organization name, contact information as well as the URL of your site, a list of
                        any URLs from which you intend to link to our Website, and a list of the URLs on our site to
                        which you would like to link. Wait 2-3 weeks for a response.</p>

                    <p>Approved organizations may hyperlink to our Website as follows:</p>
                    <ul>
                        <li>by use of our corporate name or</li>
                        <li>By use of the uniform resource locator being linked to or</li>
                        <li>By use of any other description of our Website being linked to that makes sense within
                            the context and format of content on the linking party’s site.</li>
                    </ul>

                    <p>No use of Cityplots's logo or other artwork will be allowed for linking absent a trademark
                        license agreement.</p>

                    <h3>iFrames</h3>
                    <p>Without prior approval and written permission, you may not create frames around our Webpages
                        that alter in any way the visual presentation or appearance of our Website..</p>

                    <h3>Content Liability</h3>
                    <p>We shall not be held responsible for any content that appears on your Website. You agree to
                        protect and defend us against all claims arising on your Website.</p>

                    <h3>Reservation of Rights</h3>
                    <p>We reserve the right to request that you remove all links or any particular link to our
                        Website and to amend these terms and conditions and its linking policy at any time.</p>

                    <h3>Disclaimer</h3>
                    <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties
                        and conditions relating to our website and the use of this website. Nothing in this
                        disclaimer will:</p>
                    <ul>
                        <li>Limit or exclude our or your liability for death or personal injury.</li>
                        <li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation.</li>
                        <li>Limit any of our or your liabilities in any way that is not permitted under applicable
                            law or</li>
                        <li>Exclude any of our or your liabilities that may not be excluded under applicable law.
                        </li>
                    </ul>

                    <p>The limitations and prohibitions of liability set in this Section and elsewhere in this
                        disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities
                        arising under the disclaimer, including liabilities arising in contract, in tort and for
                        breach of statutory duty.</p>
                    <p>As long as the website and the information and services on the website are provided free of
                        charge, we will not be liable for any loss or damage of any nature.</p>
                </div>
            </Container>
        </div>
    )
}

export default PrivacyPolicy
