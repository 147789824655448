import React,{useEffect} from "react";
import Slider from './Slider';
import Productslider from './Productslider';
// import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);

    });
    return (
        <>
        <Helmet>
              <link rel="canonical" href="https://www.cityplots.io/"/>
              <meta property="og:site_name" content="Cityplots" />
              <meta property="og:url" content="https://www.cityplots.io/" />
              <title>
              CityPlots.io | Find Your Dream Plot in Chennai - CityPlot App
              </title>
              <meta
                property="og:title"
                content="CityPlots.io | Find Your Dream Plot in Chennai - CityPlot App"
              />
              <meta
                name="description"
                content="Discover prime plots for sale in Chennai with the CityPlot App. Local expertise and easy search features. Start your journey to owning your dream plot today!
has context menu."
              />
              <meta
                property="og:description"
                content="Discover prime plots for sale in Chennai with the CityPlot App. Local expertise and easy search features. Start your journey to owning your dream plot today!
has context menu."
              />
              <meta
                name="keywords"
                content="CMDA Approved Residential Plots, Ultra-Luxury Villas,
                Premium Villa for Sale, Plot for Sale, Land for Sale, CMDA Approved plots, Approved plots
                near me, Top Real Estate Developer in South India, Residential projects, Commercial
                Projects, Industrial Projets, Best Real Estate Developers, Top Builders, Book Villas,
                Independent Villas for sale, Cityplots"
              />
              <meta
                property="og:image"
                content="https://www.cityplots.io/cityplots-logo.png"
              />
              <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
        </Helmet>
        <Slider/>
        <Productslider/>
        </>
    );
}

export default Home;
