import React from 'react'
import { Container,Image } from 'react-bootstrap'
import thankyouimg from '../images/thankyou.png'

const Thankyou = () => {
    return (
        <div className="thankyou-sec">
            <Container>
                
                <div className="thankyou-info">
                    <h4>Your message has been successfully sent. <br/> We appreciate you reaching out and will get back to you as soon as possible.</h4>
                </div>
               <div className="thankyou-box">
               <Image src={thankyouimg} className="thankyou-img"/>
               </div>
            </Container>
        </div>
    )
}

export default Thankyou